$(function(){
  //init
  init_dropPlate();


  //ブレイクポイント分岐
  function init_dropPlate() {
    let breakpoint = 768;
    let ww = $(window).innerWidth();

    if (ww > breakpoint) {
      dropPlate_pc();
    } else {
      dropPlate_sp();
    }
  }

  //PC->breakpointまでのドロップダウン挙動
  function dropPlate_pc() {
    let trigger = $('.c-dropPlate__trigger');//トリガー
    let closeBtn = $('.c-dropPlate__close');
    let thisContainerClass = 'is-dropPlate-open';
    let activeClass = 'is-dropPlateActive';
    controlDropPlateSlider('build');//スライダー初期化

    //クリックイベント
    trigger.on('click', function(e){
      e.preventDefault();
      let thisContainer = $(this).closest('.c-dropPlate__container');
      let getThisHeadHeight = $(this).innerHeight();
      let getThisBodyHeight = $(this).next('.c-dropPlate__body').innerHeight();
      let controlTag = $(this).attr('href').replace('#','');
      let showPlate = $('[data-dropPlate="'+ controlTag +'"]');

      if(thisContainer.hasClass(thisContainerClass)) {
        //トリガーを押されたとき、thisContainerにアクティブクラスがついている場合
      } else {
        //トリガーを押されたとき、thisContainerにアクティブクラスがついていない場合開く
        thisContainer.addClass(thisContainerClass);
        thisContainer.css('padding-bottom',getThisBodyHeight);
      }

      if ($(this).hasClass(activeClass)) {
        //トリガーにアクティブクラスがついている場合
        $(this).removeClass(activeClass);
        $(this).next('.c-dropPlate__body').removeClass(activeClass);
        thisContainer.removeClass(thisContainerClass);
        thisContainer.css('padding-bottom', '0');
        thisContainer.find('.c-dropPlate__body').css('top', '0');
      } else {
        //トリガーにアクティブクラスがついていない場合

        // 初期化
        thisContainer.find('.c-dropPlate__trigger').removeClass(activeClass);
        thisContainer.find('.c-dropPlate__body').css('top', '0');
        thisContainer.find('.c-dropPlate__body').removeClass(activeClass);

        //クラス付与＋表示
        $(this).addClass(activeClass);
        $(this).next('.c-dropPlate__body').addClass(activeClass);
        controlDropPlateSlider('init');
        showPlate.css('top',getThisHeadHeight);
      }
    });

    //クローズボタン
    closeBtn.on('click', function(e){
      e.preventDefault();
      let thisContainer = $(this).closest('.c-dropPlate__container');

      thisContainer.removeClass(thisContainerClass);
      thisContainer.css('padding-bottom', '0');
      thisContainer.find('.c-dropPlate__trigger').removeClass(activeClass);
      thisContainer.find('.c-dropPlate__body').css('top', '0');
      thisContainer.find('.c-dropPlate__body').removeClass(activeClass);
    });
  }


  //breakpoint以下のドロップダウン挙動
  function dropPlate_sp() {
    let trigger = $('.c-dropPlate__trigger');//トリガー
    let closeBtn = $('.c-dropPlate__close');
    let activeClass = 'is-dropPlateActive';
    controlDropPlateSlider('build');

    //クリックイベント
    trigger.on('click', function(e){
      e.preventDefault();
      let getThisHeadHeight = $(this).innerHeight();
      let getThisBodyHeight = $(this).next('.c-dropPlate__body').innerHeight();
      let controlTag = $(this).attr('href').replace('#','');
      let showPlate = $('[data-dropPlate="'+ controlTag +'"]');

      if ($(this).hasClass(activeClass)) {
        //閉じるとき
        $(this).removeClass(activeClass);
        $(this).closest('.c-dropPlate__item').css('padding-bottom', '0');
        $(this).next('.c-dropPlate__body').removeClass(activeClass);
        showPlate.css('top', '0');
      } else {
        //開くとき
        $(this).addClass(activeClass);
        $(this).closest('.c-dropPlate__item').css('padding-bottom', getThisBodyHeight);
        $(this).next('.c-dropPlate__body').addClass(activeClass);
        controlDropPlateSlider('init');
        showPlate.css('top',getThisHeadHeight);
      }
    });

    //クローズボタン
    closeBtn.on('click', function(e){
      e.preventDefault();
      let controlTag = $(this).attr('href').replace('#','');
      let showPlate = $('[data-dropPlate="'+ controlTag +'"]');

      $(this).closest('.c-dropPlate__body').removeClass(activeClass);
      $(this).closest('.c-dropPlate__item').css('padding-bottom', '0');
      $(this).closest('.c-dropPlate__item').find('.c-dropPlate__trigger').removeClass(activeClass);
      showPlate.css('top', '0');
    });
  }


  //スライダー制御
  function controlDropPlateSlider(command) {
    let target = $('.c-dropPlate__slide');

    if (command == 'build') {
      //スライダー作成
      target.slick({
        centerMode: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        slidesToShow: 1,
        arrows: true,
        centerPadding: 0,
        pauseOnHover: false,
        dots: true,
      });
    } else if (command == 'init') {
      //スライダーを初期位置に
      target.slick('slickGoTo', 0, true);
    } else if (command == 'destroy') {
      //スライダー解体
      target.slick('unslick');
    }
  }

});